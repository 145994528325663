import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import fotoDefault from '../assets/images/productoDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';
// import ImageCompressor   from 'image-compressor'


const productoDefault = {
    CodProducto: 0,
    Nombre: "",
    Descripcion: "",
    Stock: 0,
    StockMin: 0,
    StockMax: 0,
    PrecioVenta: 0,
    PrecioCompra: 0,
    PrecioPromocional: 0,
    CodEstado: 1,
    NombreEstado: "Activo",
    CodCategoria: 0,
    NombreCategoria: "",
    CodMarca: 0,
    NombreMarca: "",
    Foto1: "",
    Foto2: "",
    Foto3: "",
    Destacado: true,
    Publicado: true,
    FechaRegistro: "2023-11-14",
    codEmpresa: 2
}


export const useProductos = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ productos, setProductos] = useState([]);
    const [ producto, setProducto ] = useState(productoDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const [ isOpenModalListado, openModalListado, closeModalListado ] = useModal();
    const [ isOpenModalListadoProductos, openModalListadoProductos, closeModalListadoProductos ] = useModal();

    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoFile2, setFotoFile2 ] = useState(null); 
    const [ fotoFile3, setFotoFile3 ] = useState(null); 
    const [ fotoUrlProducto, setFotoUrlProducto ] = useState(fotoDefault);
    const [ fotoUrlProducto2, setFotoUrlProducto2 ] = useState(fotoDefault);
    const [ fotoUrlProducto3, setFotoUrlProducto3 ] = useState(fotoDefault);
    const urlProductos = process.env.REACT_APP_PROMETHEUS_API + '/productos'


    useEffect(() => {
        listarProductos();
    }, []);

    const listarProductos = async () => {
        const response = await Fetch({
            url: urlProductos + '?'+ new URLSearchParams({
                cod_empresa  : stateUser.codEmpresa
            })
        });
        // const response = await AuthFetch({
        //     url: urlBasePlamin + '/Alertas/seguimiento?'+ new URLSearchParams({
        //         codAlerta : codAlertaPrincipal
        //     })
        // });
        if (response.isValid) {
            setProductos(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerProducto = async (codProducto) => {
        console.log("Entro al obtener producto -> ", codProducto)
        if (codProducto) {
            const response = await AuthFetch({
                url: urlProductos + '/obtenerProducto?'+ new URLSearchParams({
                    cod_producto  : codProducto,
                    cod_empresa  : stateUser.codEmpresa,

                })
            });
            console.log("response del obtenerproducto-> ", response)
            if (response.isValid) {
                setProducto(response.content);
                setFotoUrlProducto(response.content.Foto1)
                setFotoUrlProducto2(response.content.Foto2)
                setFotoUrlProducto3(response.content.Foto3)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setProducto(productoDefault);
            setFotoUrlProducto(fotoDefault)
            setFotoUrlProducto2(fotoDefault)
            setFotoUrlProducto3(fotoDefault)
        }
        openModal();
    }

    const productoEliminar = async (codProducto) =>{
        if (codProducto) {
            const response = await AuthFetch({
                url: urlProductos + '/obtenerProducto?'+ new URLSearchParams({
                    cod_producto  : codProducto,
                    cod_empresa  : stateUser.codEmpresa,
                })
            });


            if (response.isValid) {
                setProducto(response.content);
                // setFotoUrlProducto(response.content.Foto)
                setFotoUrlProducto(response.content.Foto1)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setProducto(productoDefault);
            setFotoUrlProducto(fotoDefault)
        }
        openModalEliminar();
    }

    const eliminarProducto = async (codProducto)=>{
        const response = await AuthFetch({
            url: urlProductos + '/EliminarProducto?'+ new URLSearchParams({
                cod_producto  : codProducto,
                cod_empresa  : stateUser.codEmpresa,
            }),
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarProductos()
        }
        closeModalEliminar()
    }

    const guardarProductoConFoto = async () => {
        console.log("guardar imagenFoto")



        if (fotoFile != null){


            if(fotoFile !== null && fotoFile.size > 1049456){
                notify('El tamaño máximo de la imagen debe ser de 1MB - Foto1 ','error')
                return;
            }
            if(fotoFile2 !== null && fotoFile.size > 1049456){
                notify('El tamaño máximo de la imagen debe ser de 1MB Foto2','error')
                return;
            }
            if(fotoFile3 !== null && fotoFile.size > 1049456){
                notify('El tamaño máximo de la imagen debe ser de 1MB Foto3','error')
                return;
            }

            const fotoData = new FormData();
            fotoData.append('archivo', fotoFile);
            console.log("Nombre del producto -> ", producto)
            const response = await Fetch({
                url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                    NombreCarpeta:"prueba",
                    // NombreSubCarpeta: "/productos",
                    NombreArchivo: `productos/${producto.Nombre}1`,
                }),
                headers:{
                    contentType: 'image/*'
                    // 'Content-Type':'image/*'
                },
                method:'POST',
                body : fotoData
            });
            console.log("RESPONSE FOTO -> ", response)

            if (response.isValid) {
                // VALIDAMOS LA 2DA FOTO
                if (fotoFile2 != null){
                    const fotoData2 = new FormData();
                    fotoData2.append('archivo', fotoFile2);
                    console.log("Nombre del producto -> ", producto)
                    const response = await Fetch({
                        url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                            NombreCarpeta:"prueba",
                            NombreArchivo: `productos/${producto.Nombre}2`,
                        }),
                        headers:{
                            contentType: 'image/*'
                        },
                        method:'POST',
                        body : fotoData2
                    });
                    console.log("RESPONSE FOTO2-> ", response)
                }        
                // VALIDAMOS LA 3ERA FOTO
                if (fotoFile3 != null){
                    const fotoData3 = new FormData();
                    fotoData3.append('archivo', fotoFile3);
                    console.log("Nombre del producto -> ", producto)
                    const response = await Fetch({
                        url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                            NombreCarpeta:"prueba",
                            NombreArchivo: `productos/${producto.Nombre}3`,
                        }),
                        headers:{
                            contentType: 'image/*'
                            // 'Content-Type':'image/*'
                        },
                        method:'POST',
                        body : fotoData3
                    });
                    console.log("RESPONSE FOTO3-> ", response)
                    if (response.isValid) {
                    }
                }
                setFotoFile(null)
                setFotoFile2(null)
                setFotoFile3(null)
                producto.Foto1=`https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=prueba&NombreImagen=/productos/${producto.Nombre}1`
                producto.Foto2=`https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=prueba&NombreImagen=/productos/${producto.Nombre}2`
                producto.Foto3=`https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=prueba&NombreImagen=/productos/${producto.Nombre}3`
                guardarEditarProducto()
            }else{
                notify(response.exceptions[0].description, 'error');
            }
        }else{
            guardarEditarProducto()
        }
    }
    
    const guardarEditarProducto = async () => {
        if (!producto.Nombre){ notify("Debe ingresar un nombre de producto",'error'); return }
        if (!producto.CodCategoria){
            notify("Debe seleccionar una categoria",'error')
            return
        }
        if (!producto.CodMarca){
            notify("Debe seleccionar una marca",'error')
            return
        }



        const esGuardar = producto.CodProducto <= 0;
        const response = await AuthFetch({
            url: urlProductos,
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodProducto: producto.CodProducto }),
                Nombre:producto.Nombre,
                Descripcion: producto.Descripcion,
                Stock:producto.Stock,
                StockMin: producto.StockMin,
                StockMax: producto.StockMax,
                PrecioVenta:producto.PrecioVenta,
                PrecioCompra:producto.PrecioCompra,
                PrecioPromocional:producto.PrecioPromocional,
                CodEstado: producto.CodEstado,
                NombreEstado: producto.NombreEstado,
                CodCategoria:producto.CodCategoria,
                NombreCategoria:producto.NombreCategoria,
                CodMarca:producto.CodMarca,
                NombreMarca:producto.NombreMarca,
                Destacado:producto.Destacado,
                Publicado:producto.Publicado,
                Foto1:producto.Foto1,
                Foto2:producto.Foto2,
                Foto3:producto.Foto3,
                FechaRegistro:producto.FechaRegistro,
                codEmpresa:stateUser.codEmpresa
            })
        });

        console.log("PRODUCTO => ", producto )
        console.log("Response del guardado -> ", response)
        if (response.isValid) {
            setProducto(productoDefault);
            await listarProductos();
            closeModal()
            notify(response.content, response.isValid ? 'success' : 'error');
        }
    }

    const editarValorProducto = (key, value) => {
        console.log(`editarValorProducto -> ${key} -> ${value}` )
        setProducto(producto => {
            return {
                ...producto,
                [key]: value
            }
        });
    }

    return {
        producto, 
        listarProductos, 
        guardarProductoConFoto,
        productos,
        editarValorProducto,
        obtenerProducto,
        isOpenModal,
        closeModal,
        fotoUrlProducto,
        fotoUrlProducto2,
        fotoUrlProducto3,
        setFotoFile,
        setFotoFile2,
        setFotoFile3,
        setFotoUrlProducto,
        setFotoUrlProducto2,
        setFotoUrlProducto3,
        eliminarProducto,
        isOpenModalEliminar, 
        closeModalEliminar,
        productoEliminar 
    }

}