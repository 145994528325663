import React, { useEffect, useState, useRef, useContext } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
import { useCajas } from '../../../hooks/useCajas';
import { UserContext } from '../../../context/provider/UserProvider';
import {formatearFecha} from '../../../utils/utils.js'
import { Side } from '../../components/modal/Side';
import { DatePickerP } from '../../components/pickers/DatePicker';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import 'dayjs/locale/es';
import { styled } from '@mui/system';




export const Caja = () => {
    
    const { stateUser, signOut } = useContext(UserContext)
    const { caja, listarCajas, cajas, editarValorCaja, obtenerCaja, isOpenModal, closeModal,eliminarCaja, isOpenModalEliminar, closeModalEliminar, cajaEliminar,guardarEditarCaja } = useCajas()
    const [ fechaSeleccionada, setFechaSeleccionada ] = useState(dayjs());
    const idTablaCajas = "tablaCajas"



    const CustomTextField = styled('input')({
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        outline: 'none',
        caretColor: 'black',
        '&::placeholder': {
            color: 'gray', // Cambia el color del placeholder
        },
    });

    const cambiarFecha = (nuevaFecha) => {
        setFechaSeleccionada(nuevaFecha);
        editarValorCaja("FechaApertura", nuevaFecha)
    };


    return (
        <>
        <ContenedorParametros>

        <BotonNuevo onClick={() => {obtenerCaja();cambiarFecha(dayjs())}} icono="fa fa-plus-circle"> Nuevo</BotonNuevo>
        {/* <BotonNuevo onClick={() => {obtenerCaja();cambiarFecha(dayjs())}} icono="fa fa-plus-circle"> Nuevo</BotonNuevo> */}
            <SearchTable tablaId={idTablaCajas}></SearchTable>
        </ContenedorParametros>

        <div className="containerScroll relative contenedor-tabla ">
                    <table id={idTablaCajas} className="tableResponsiveProductos " >
                        <thead className=''>
                            <tr >
                                <th>N</th>
                                <th>CodCaja</th>
                                <th>Fecha Apertura</th>
                                <th>Fecha Cierre</th>
                                <th>Usuario</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cajas.map((caja,i) => (
                                <tr key={caja.CodCaja} className="text-center center center-items">
                                    <td data-th="">{++i}</td>
                                    <td data-th="Cod Caja">{caja.CodCaja}</td>
                                    <td data-th="Fecha Apertura">{formatearFecha(caja.FechaApertura)}</td>
                                    <td data-th="Fecha Cierre">{caja.Estado== 'Aperturada'? '-' : formatearFecha(caja.FechaCierre)} </td>
                                    <td data-th="Usuario">{caja.UsuarioCreacion}</td>
                                    <td data-th="Estado"><span className={`cursor-pointer text-white ${caja.Estado=='Aperturada'? ' bg-green-500 ':' bg-red-500 '} px-2 py-2 rounded uppercase text-[13px] font-bold `}>{caja.Estado}</span></td>
                                    <td data-th="Opciones"> 
                                        {caja.Estado == 'Aperturada' && 
                                        <>
                                            <button onClick={() => obtenerCaja(caja.CodCaja)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded">
                                                <i className="fa fa-money"></i>
                                            </button>
                                            <button  onClick={()=> cajaEliminar(caja.CodCaja)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                                <i className="fas fa-trash"></i>
                                            </button>
                                                
                                        </>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            <Side 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarEditarCaja()}
                title={caja.CodCaja? `Cerrar Caja [${caja.CodCaja}] ` :"Aperturar Caja"}
            >

                <div className='text-center text-gray-500 text-center justify flex flex-col center'>
                    
                    Fecha y Hora
                {/* <DatePickerP date={fechaCaja} setDate={setFechaCaja}></DatePickerP> */}




                <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
                        <MobileDateTimePicker
                            value={fechaSeleccionada}
                            onChange={cambiarFecha}
                            renderInput={(props) => (
                                <CustomTextField
                                    {...props}
                                />
                            )}
                        />
                </LocalizationProvider>


                </div>
            </Side>

            <Side 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarCaja(caja.CodCaja)}
                title= "Eliminar Caja"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center text-gray-800">¿Está seguro de eliminar la Caja? </label>
                            <hr></hr>
                            <div className='text-center mt-5  content-center items-center'>
                                <div className='border'>
                                    <label className="text-center  text-gray-800  "> {caja.Nombre}</label>
                                </div>
                            </div>
                        </div>
                    </div>
            </Side>   
        </>
    )

}