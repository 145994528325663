import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import fotoDefault from '../assets/images/clienteDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';


const clienteDefault = {
    CodCliente:0,
    TipoDocumento:1,
    NumeroDocumento:"",
    Nombres:"",
    ApellidoPaterno:"",
    ApellidoMaterno:"",
    Celular:"",
    Direccion:"",
    Correo:"",
    Estado:"Activo",
    Foto:"https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=pruebas&NombreImagen=/clientes/Marciana",
}


export const useClientes = () => {
    const { stateUser, signOut } = useContext(UserContext)
    const [ clientes, setClientes] = useState([]);
    const [ cliente, setCliente ] = useState(clienteDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const [ isOpenModalListado, openModalListado, closeModalListado ] = useModal();
    const [ isOpenModalListadoProductos, openModalListadoProductos, closeModalListadoProductos ] = useModal();

    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlCliente, setFotoUrlCliente ] = useState(fotoDefault);
    
    const urlCliente = process.env.REACT_APP_PROMETHEUS_API + '/clientes'


    const cargarDatosCliente = async () => {
        console.log("ENTRO A CARGAR DATOS -> ", cliente.NumeroDocumento)
        if (cliente.NumeroDocumento.length == 8 ){
            const response = await Fetch({
                url: `https://identidadwebapi.abexacloud.com/api/Identidad/obtenerDni?dni=${cliente.NumeroDocumento}`
            });
            if (response.isValid) {
                setCliente({
                    CodCliente: 0,
                    Nombres: response.content.nombres,
                    ApellidoPaterno: response.content.apellidoPaterno,
                    ApellidoMaterno: response.content.apellidoMaterno,
                    TipoDocumento: 1,
                    NumeroDocumento: response.content.dni,
                })
            } else {
                notify("No se encontró información", "error")
                setCliente(clienteDefault)
            }
            console.log("RESPONSE DE OBTENER DATOS CLIENTE -> ", response)
        }else{
        setCliente(clienteDefault)
        }       
    }

    useEffect(() => {
        listarClientes();
    }, []);

    const listarClientes = async () => {
        const response = await AuthFetch({
            url: urlCliente + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa
            })
        });
        if (response.isValid) {
            setClientes(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerCliente = async (codCliente) => {
        if (codCliente) {
            const response = await AuthFetch({
                url: urlCliente + '/obtenerCliente?'+ new URLSearchParams({
                    cod_empresa : stateUser.codEmpresa,
                    cod_cliente : codCliente
                })
            });
            if (response.isValid) {
                setCliente(response.content);
                setFotoUrlCliente(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCliente(clienteDefault);
            setFotoUrlCliente(fotoDefault)
        }
        openModal();
    }

    const clienteEliminar = async (codCliente) =>{
        if (codCliente) {
            const response = await Fetch({
                url:urlCliente + `/clientes/${codCliente}`
            });

            if (response.isValid) {
                setCliente(response.content);
                setFotoUrlCliente(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCliente(clienteDefault);
            setFotoUrlCliente(fotoDefault)
        }
        openModalEliminar();
    }

    const eliminarCliente = async (codCliente)=>{
        const response = await Fetch({
            url: urlCliente + `/clientes/${codCliente}`,
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarClientes()
        }
        closeModalEliminar()
    }

    const guardarClienteConFoto = async () => {
        if (fotoFile != null){

            const fotoData = new FormData();
            fotoData.append('archivo', fotoFile);
            const response = await Fetch({
                url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                    NombreCarpeta:"pruebas",
                    NombreArchivo: `/clientes/${cliente.Nombres}`,
                }),
                headers:{
                    contentType: 'image/*'
                    // 'Content-Type':'image/*'
                },
                method:'POST',
                body : fotoData
            });
            if (response.isValid) {
                setFotoFile(null)
                cliente.Foto=`https://minioapi.prometheusperu.com/api/Archivo/verImagen?NombreCarpeta=pruebas&NombreImagen=/clientes/${cliente.Nombres}`
                guardarEditarCliente()
            }else{
                notify(response.exceptions[0].description, 'error');
            }
        }else{
            guardarEditarCliente()
        }
    }
    
    const guardarEditarCliente = async () => {
        const esGuardar = cliente.CodCliente <= 0;
        const response = await Fetch({
            url: urlCliente + '/clientes',
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodCliente: cliente.CodCliente }),
                TipoDocumento:cliente.TipoDocumento,
                NumeroDocumento:cliente.NumeroDocumento,
                Nombres:cliente.Nombres,
                ApellidoPaterno:cliente.ApellidoPaterno,
                ApellidoMaterno:cliente.ApellidoMaterno,
                Celular:cliente.Celular,
                Direccion:cliente.Direccion,
                Correo:cliente.Correo,
                Estado:cliente.Estado || 'Activo',
                Foto:cliente.Foto,
            })
        });
        console.log("Response del guardado -> ", response)
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setCliente(clienteDefault);
            await listarClientes();
            closeModal()
        }
    }

    const editarValorCliente = (key, value) => {
        setCliente(cliente => {
            return {
                ...cliente,
                [key]: value
            }
        });
    }

    return { cliente, listarClientes, guardarClienteConFoto,clientes, editarValorCliente, obtenerCliente,
            isOpenModal, closeModal, fotoUrlCliente,setFotoFile,setFotoUrlCliente,eliminarCliente, isOpenModalEliminar, 
            closeModalEliminar, clienteEliminar, cargarDatosCliente }
}