import React from 'react';
import '../../../assets/css/components/botonneon.css'


export const BotonNeon = (props) => {
    return (
        <button {...props} className={` custom-btn ${props.estilo} text-[20px] text-white`}>
            <i className="" style={{color: '#FFFFFF', fontSize:'18px'}}></i> 
            <span style={{fontSize: '20px', color: '#FFFFFF'}}>{props.children || 'INGRESAR'}</span>
        </button>
    )
}

