import React, { forwardRef } from 'react';

import DatePicker, { registerLocale, setDefaultLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export const DatePickerP = ({
    date,
    setDate,
    styles
}) => {

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <input onClick={onClick} ref={ref} className=" text-center text-gray-500  h-[30px] rounded  bg-white border text-xl focus:outline-none focus:ring-1 focus:ring-orange-500" defaultValue={value} style={styles} readOnly/>
    ));

    return (
        <DatePicker
            locale="es" 
            selected={date }
            onChange={(date) => setDate(date)}
            customInput={<DateInput/>} 
            // dateFormat="yyyy-MM-dd"
            dateFormat="dd/MM/yyyy hh:mm:ss"
        ></DatePicker>
    )
}