import React, { useRef, useEffect, useContext } from 'react';
import { NavLink,useHistory } from "react-router-dom";
import $ from 'jquery';
import { UserContext } from '../../../context/provider/UserProvider';

export const Menu = React.forwardRef(({isOpen, openMenu, closeMenu, toggleContentMenu}, ref) => {    
    const { stateUser } = useContext(UserContext)
    const splitRef = useRef();

    useEffect(() => {
        
        const li = document.getElementsByClassName("li-nav-div")
        for (var i = 0; i < li.length; i++) {
            const linkSubMenu = li[i].parentNode.querySelectorAll('.li-a-submenu')
            
            let up = true

            for (var j = 0; j < linkSubMenu.length; j++) {
                const ulSubMenu = linkSubMenu[j].parentNode.parentNode.parentNode
    
                if (linkSubMenu[j].classList.contains("active")) {
                    const liNavDiv = ulSubMenu.parentNode;
                    liNavDiv.querySelector('.li-nav-div').children[0].classList.add("active")
                    up = false
                }
            }

            if(up) $(li[i]).parent().children('ul').slideUp();
        }

    })
    const cerrarMenu = () =>{
        toggleContentMenu(false)
        closeMenu()
    }
    // const cerrarMenu =()=>{

    //     ()=>closeMenu()()
    // }
    // MENU PADRE
    const toggleMenu = (e) => {
        let element = e.target
        while (!element.classList.contains('li-nav-div')) {
            element = element.parentNode
        }
        const menuParents = $('.li-nav-div')
        for (let i = 0; i < menuParents.length; i++) {
            const menuParent = menuParents[i]
            if (element != menuParent) {
                menuParent.children[0].classList.remove('active')
                $(menuParent.parentNode.children[1]).slideUp()
            }
        }
        if (element.children[0].classList.contains('active')) {
            $(element.parentNode.querySelector('.ul-submenu')).slideUp();
        } else {
            $(element.parentNode.querySelector('.ul-submenu')).slideDown();
        }

        element.children[0].classList.toggle('active')
    }

    return (
        <>
            <div className={`flex menu lg:absolute relative h-full  ${isOpen ? 'active' : ''}`}>
                {/* <ul ref={ref} className="ul-nav lg:py-[20px] py-0 bg-[#272727]">
                    <li id="li" key={1} className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    <span className="text-menu">NOMMENU</span>
                                    <i className="icon-menu fas fa-sort-down"></i>
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={11} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/dashboard' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">nommenuhijo</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </li>  
                </ul> */}


            
                <ul ref={ref} className="ul-nav lg:py-[25px] py-1 ">
                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onBlur={(e) => toggleMenu(e)} onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a ">
                                <div className="w-full flex justify-between">
                                    <span className="text-menu "> <i className='fa fa-tachometer ' style={{fontSize:'20px',marginRight:'15px'}}/>Inicio</span>
                                </div>                                
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={1} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/dashboard' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"> <i className=' fa fa-fire' /> Principal</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </li>  

                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/ventas'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"> <i className='fa fa-shopping-basket' style={{fontSize:'20px',marginRight:'15px'}}/>Ventas</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={2} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/caja' className="nav-link li-a-submenu"  onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Caja </span>
                                    </NavLink>
                                </div>
                            </li>


                            <li key={3} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/registro-ventas' className="nav-link li-a-submenu"  onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/>  Registrar Venta</span>
                                    </NavLink>
                                </div>
                            </li>
                        {/* </ul> */}
                        {/* <ul className="ul-submenu" style={{padding: 0}}> */}
                            <li key={4} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/lista-ventas' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Ventas</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>

                    </li>


                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/ventas'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"> <i className='fa fa-shopping-bag' style={{fontSize:'20px',marginRight:'15px'}}/>Compras</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={5} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/registro-compra' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Registrar Compra</span>
                                    </NavLink>
                                </div>
                            </li>
                        {/* </ul> */}
                        {/* <ul className="ul-submenu" style={{padding: 0}}> */}
                            <li key={6} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/lista-compras' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Compras</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>

                    </li>


                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/productos'} className="nav-link li-a-submenu"> */}
                                        <span className=" text-menu"><i className='fa fa-box ' style={{fontSize:'20px',marginRight:'15px'}}/> Productos</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={7} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/productos' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Productos</span>
                                    </NavLink>
                                </div>
                            </li>
                            
                            <li key={8} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/compartir-productos' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Compartir Productos</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={9} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/inventario-productos' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Inventario</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={10} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/categorias' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Categorias</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={10} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/marcas' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Marcas</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>

                    </li>  








                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/ventas'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"><i className=' fa fa-briefcase' style={{fontSize:'20px',marginRight:'15px'}}/>Servicios</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            
                            {/* <li key={11} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/registro-servicio' className="nav-link li-a-submenu">
                                        <span className="fa fa-circle text-[15px] text-sub-menu"> Registrar Servicio</span>
                                    </NavLink>
                                </div>
                            </li> */}

                        {/* </ul> */}
                        {/* <ul className="ul-submenu" style={{padding: 0}}> */}
                            <li key={11} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/lista-servicios' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Servicios</span>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>

                    </li>

                    
                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/clientes'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"><i className=' fa fa-users' style={{fontSize:'20px',marginRight:'15px'}}/>Clientes</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={12} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/clientes' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Clientes</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={13} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/gestion-deudas' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Gestión de Deudas</span>
                                    </NavLink>
                                </div>
                            </li>
                            {/* <li key={14} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/clientes' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">Lista Clientes</span>
                                    </NavLink>
                                </div>
                            </li> */}

                        </ul>
                    </li>  

                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/proveedores'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"><i className='fa fa-user ' style={{fontSize:'20px',marginRight:'15px'}}/>Proveedores</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={14} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/proveedores' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Lista Proveedores</span>
                                    </NavLink>
                                </div>
                            </li>
                            {/* <li key={14} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/clientes' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">Lista Clientes</span>
                                    </NavLink>
                                </div>
                            </li> */}

                        </ul>
                    </li>  

                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/proveedores'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"><i className='fa fa-file ' style={{fontSize:'20px',marginRight:'15px'}}/>Reportes</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={15} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/reporte1' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Reporte1</span>
                                    </NavLink>
                                </div>
                            </li>
                            {/* <li key={14} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/clientes' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">Lista Clientes</span>
                                    </NavLink>
                                </div>
                            </li> */}

                        </ul>
                    </li>  

                    <li id="li" className="waves-effect ul-nav-li active-li">
                        <div className="li-nav-div" onClick={(e) => toggleMenu(e)}>
                            <a className="nav-link li-a">
                                <div className="w-full flex justify-between">
                                    {/* <NavLink to={'/proveedores'} className="nav-link li-a-submenu"> */}
                                        <span className="  text-menu"><i className='fa fa-cog ' style={{fontSize:'20px',marginRight:'15px'}}/>Sistema</span>
                                    {/* </NavLink> */}
                                </div>
                            </a>
                        </div>
                        <ul className="ul-submenu" style={{padding: 0}}>
                            <li key={16} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/permisos' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Usuarios</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={17} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/usuarios' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Permisos</span>
                                    </NavLink>
                                </div>
                            </li>
                            <li key={18} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/configuracion' className="nav-link li-a-submenu" onClick={()=>cerrarMenu()}>
                                        <span className="text-sub-menu"><i className=' fa fa-fire'/> Configuracion</span>
                                    </NavLink>
                                </div>
                            </li>
                            {/* <li key={14} id="li-submenu" className="waves-effect ul-nav-li-submenu">
                                <div className="li-nav-div-submenu">
                                    <NavLink to='/clientes' className="nav-link li-a-submenu">
                                        <span className="text-sub-menu">Lista Clientes</span>
                                    </NavLink>
                                </div>
                            </li> */}

                        </ul>
                    </li>  





                </ul>

                {/* <div ref={splitRef} id="split" onClick={() => toggleContentMenu(false)} className=" flex items-center  hidden justify-center w-[1px] bg-[#5086c1] hover:bg-[#5086c1] cursor-pointer"> */}
                {/* <div ref={splitRef} id="split" onClick={()=>closeMenu()} className=" flex items-center  hidden justify-center w-[1px] bg-[#5086c1] hover:bg-[#5086c1] cursor-pointer">
                    <i id="icon-split" className="icon-split fas fa-caret-square-left"></i>
                </div> */}
            </div>
        </>

    )
})