
import React, { useEffect, useState, useRef,useContext } from 'react';
import '../../assets/css/components/cardStyle.css'
import { SocketContext } from '../../context/provider/SocketProvider';
import { notify } from '../../utils/utils';
import logo from '../../assets/images/logoPrometheus.fw.png'
import { Loading } from '../components/Loading';

// import icono from '../../../public/favicon.ico'
// import logo from '../../../assets/images/logoPrometheuslargo.png'

// import io from "socket.io-client";

// const socket = io(process.env.REACT_APP_SOCKET_SESION);


export const Dashboard = () => {
    
    const { stateSocket } = useContext(SocketContext);

    const showNotification = async () => {
        const registration = await navigator.serviceWorker.getRegistration()
        if( ! registration ) return alert("No hay un Service Worker Dash :(")
    
        registration.showNotification("NOTIFICACION!", {
            body: 'Mensaje de Prueba DashBoard',
            img: logo,
            // icon: icono
        })
    }

    useEffect(() => {
        if (Object.keys(stateSocket.payload).length > 0) {
            notify('¡Se Envio una Recarga!', 'info', { autoClose: true });
        }
    }, [stateSocket.payload])
    
    const Notificar = ()=>{
        console.log("entro a la funcion de notificacion")
        showNotification()
    }

    // useEffect(() => {
    //     socket.on("notificacionPush", mensaje=>{
    //         console.log("ANTES DE LA NOTIFICACION PUSH -> ", mensaje)
    //         showNotification()
    //         console.log("NOTIFICACION PUSH -> ", mensaje)
    //     })
    // }, [])

    // const abrirFiltro = () => {
    //     console.log("ENTRO AL ABRIR -> ")
    //     // document.querySelector(".jsFilter").addEventListener("click", function () {
    //         document.querySelector(".filter-menu").classList.toggle("active");
    //     // });
    // }


    return (
        <>

            <div className={`mt-5 text-center text-3xl text-orange-600`}> 
                <div>
                    BIENVENIDOS A PROMETHEUS SOFT 
                </div>
            </div>



{/* <Loading></Loading> */}





            {/* <button onClick={()=>Notificar()}className='bg-blue-500 rounded p-5'> CLICK</button> */}

            
                    {/* <div className="app-content-actions">
                    
                        <div className="app-content-actions-wrapper">
                            <div className="filter-button-wrapper">
                            <button onClick={()=>abrirFiltro()} className="action-button filter jsFilter"><span>Filterr</span></button>
                                        <div className="filter-menu bg-blue-600 w-[auto]" style={{border:"1px solid red"}}>

                                            <label  >Category</label>
                                            <select > 
                                                <option className='bg-blue-500'>All Categories</option>
                                                <option className='bg-blue-500'>Furniture</option>                     
                                                <option className='bg-blue-500'>Decoration</option>
                                                <option className='bg-blue-500'>Kitchen</option>
                                                <option className='bg-blue-500'>Otros</option>
                                            </select>
                                            <label>Status</label>
                                            <select className='bg-blue-500'>
                                                <option className='bg-blue-500'>All Status</option>
                                                <option className='bg-blue-500'>Active</option>
                                                <option className='bg-blue-500'>Disabled</option>
                                            </select>

                                            <div className="filter-menu-buttons">

                                                <button className="filter-button reset">
                                                    Reset
                                                </button>
                                                <button className="filter-button apply">
                                                    Apply
                                                </button>
                                            </div>
                                            
                                        </div>
                            </div>
                        
                        
                </div>
            </div>
 */}












        {/* ///////////////////////////////////////////// */}
                
                {/* <div className="flex justify-between width-full justify-center card bg-[#4b1c71] " >
                    <div className='ml-2' >
                        <i className="fa fa-arrow-up text-[40px] text-green-600 mt-10"></i>
                    </div>    

                    <div clasName="mt-[50px]">
                        <p className='cardMonto'>S/5000.00</p>
                        <p className='cardTitulo'>Ventas 7 días</p>
                    </div>

                    <div className=' ' >
                        <button onClick={{}} type="button"  className=" mr-3 mt-2 bg-purple-800 px-1 rounded-md text-gray-400 hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <i className="fa fa-ellipsis-h text-xl "></i>
                        </button>
                    </div>
                </div>

                <div className="app-container">
                    
                    <button className="action-button filter jsFilter"><span>Filterr</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"/></svg></button>
                    <div className="filter-menu">
                        <label>Category</label>
                        <select>
                            <option>All Categories</option>
                            <option>Furniture</option>                     
                            <option>Decoration</option>
                            <option>Kitchen</option>
                            <option>Bathroom</option>
                        </select>

                        <label>Status</label>
                        <select>
                            <option>All Status</option>
                            <option>Active</option>
                            <option>Disabled</option>
                        </select>
                        <div className="filter-menu-buttons">
                            <button className="filter-button reset">
                                Reset
                            </button>
                            <button className="filter-button apply">
                                Apply
                            </button>
                        </div>
                    </div>
                
                </div> */}
                {/* <div className="flex justify-between width-full justify-center card bg-[#5086c1] " >
                    
                    <div className='ml-2' >
                        <i className="fa fa-arrow-down text-[40px] text-red-600 mt-10"></i>
                    </div>    

                    <div clasName="mt-[50px]">
                        <p className='cardMonto'>S/0.00</p>
                        <p className='cardTitulo'>Ventas 7 días</p>
                    </div>

                    <div className=' ' >
                        <button onClick={{}} type="button"  className=" mr-3 mt-2 bg-purple-800 px-1 rounded-md text-gray-400 hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <i className="fa fa-ellipsis-h text-xl "></i>
                        </button>
                    </div>

                </div> */}
                


        
        </>
    )

}