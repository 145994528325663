import React from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const Modal = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    cerrarAlGuardar = false,
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true}
}) => {
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 

    return (
    <>  
        {isOpen ? (
            <>
                <Draggable handle="strong">
                    <div className="justify-center items-center flex  fixed inset-0 z-40 outline-none focus:outline-none">
                        <div className={`relative w-${width ? width : 'auto'} my-6 mx-auto max-w-[90%]`}>
                        {/*content*/}
                            <div className=" border-0 rounded shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                
                                {/*header*/}
                                <strong>
                                    <div className="divMovibleCabecera h-[50px] bg-[#f6682f]  flex items-start justify-between p-3 border-b border-solid border-[#f6682f] rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            {title}
                                        </h3>
                                        {/* <button className=" hover:bg-red-500 px-1 rounded border lg:text-black lg:z-[1060]" onClick={closeModal}>
                                            <i className="fas fa-times text-xl"></i>
                                        </button> */}
                                    </div>
                                </strong>

                                {/*body*/}

                                    <div className="relative p-3 flex-auto overflow-y-scroll containerScroll">
                                        { children }
                                    </div>

                                {/*footer*/}
                                <hr className=""/>
                                <div className="flex items-center justify-end p-6">
                                {validButton.confirm ?
                                    <button
                                        className="bg-green-600 hover:bg-green-700 text-white active:bg-green-600 font-bold capitalize text-[20px] px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 "
                                        type="button"
                                            onClick={async () => {
                                                mostrarSpinner();
                                                await action();
                                                ocultarSpinner();
                                                // cerrarAlGuardar && closeModal()
                                            }}
                                        >
                                            {
                                                spinner 
                                                ? <Spinner spinner={spinner.toString()}></Spinner>
                                                : <span>{textButtons.confirm}</span>
                                            }
                                    </button>
                                    : ''
                                }
                                {validButton.denied ?
                                    <button
                                    className="text-white-500 hover:bg-red-600 bg-red-500 rounded font-bold capitalize px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-[20px]"
                                    type="button"
                                    onClick={closeModal}
                                    >
                                        {textButtons.denied} 
                                    </button>
                                    : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </>
        ) : null}
        </>
    );
}