import React from 'react';
import '../../../assets/css/components/botonnuevo.css'



export const BotonNuevo = (props) => {
    return (
        <div className="wrapper ">

            <button {...props} className=" btn-circle btn-xl botonNuevo rounded-xl py-2 px-2 lg:px-3 lg:mr-2">
                <i className="fa fa-plus mr-1 ml-1 text-[#ffffff] text-[20px] lg:text-[25px] " ></i> 
                <span className='lg:hidden text-xl text-[#ffffff]  '>{props.children || 'Nuevo'}</span>
            </button>

        </div>
    )
}

        